import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// import {
//   DesktopOutlined,
//   FileOutlined,
//   PieChartOutlined,
//   TeamOutlined,
//   UserOutlined,
// } from "@ant-design/icons";
import { Breadcrumb, Button, Layout as LayoutAntd, Menu, theme } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as OrderIcon } from "../assets/icons/order.svg";
import { ReactComponent as CustomerIcon } from "../assets/icons/customer.svg";
import { ReactComponent as BusinessUserIcon } from "../assets/icons/business-users.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as MenuArrow } from "../assets/icons/menu-down-arrow.svg";
import { ReactComponent as DineIconDefault } from "../assets/icons/Dine-Dash-Default.svg";
import { ReactComponent as DineIconSelected } from "../assets/icons/Dine-Dash-Selected.svg";
import { ReactComponent as NudgesDefault } from "../assets/icons/Nudge-Default.svg";
import { ReactComponent as NudgesSelected } from "../assets/icons/Nudge-Selected.svg";
import {ReactComponent as SettingIcon} from "../assets/icons/settingIcon.svg"
import {ReactComponent as SettingIconDark} from "../assets/icons/settingDark.svg"

import notificationIcon from "../assets/icons/notification.svg";
import logoutIcon from "../assets/icons/logout.svg";
import sideBarImage from "../assets/image/login-image.svg";
import useOnClickOutside from "../hook/useOnClickOutside";
import AuthContext from "../context/AuthContext";
import { logoutAdmin } from "../http-config/service/auth";
import { useNotification } from "../hook/useNotification";
import collapseCloseIcon from "../assets/icons/Group 13994.svg";
import {
  SideBarMenuTitle,
  SideBarMenuData,
} from "../commas/applicationConstant";

const { Header, Content, Footer, Sider } = LayoutAntd;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const Layout = ({ children }) => {
  const { notification, contextHolder } = useNotification();
  const { authUser, setAuthUser } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(true);
  const [logoutMenu, setLogoutMenu] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1200) {
        setCollapsed(true);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const selectMenu = useMemo(() => {
    return location?.pathname.split("/")[1];
  }, [location]);

  const logout = (e) => {
    logoutAdmin()
      .then((res) => {
        setAuthUser({});
        localStorage.clear();
      })
      .catch((err) => {
        console.log(err, "logout error");
      });
  };
  const sideBarMenu = [
    {
      label: SideBarMenuTitle.DASHBOARD,
      key: SideBarMenuData.DASHBOARD,
      icon: <DashboardIcon />,
    },
    {
      label: SideBarMenuTitle.BUSINESS_USERS,
      key: SideBarMenuData.BUSINESS_USERS,
      icon: <BusinessUserIcon />,
    },
    {
      label: SideBarMenuTitle.CUSTOMER,
      key: SideBarMenuData.CUSTOMER,
      icon: <CustomerIcon />,
    },
    {
      label: SideBarMenuTitle.NUDGES,
      key: SideBarMenuData.NUDGES,
      icon:
        location?.pathname?.split("/")?.[1] === SideBarMenuData.NUDGES ? (
          <NudgesDefault />
        ) : (
          <NudgesSelected />
        ),
    },
    {
      label: SideBarMenuTitle.DINE_IN,
      key: SideBarMenuData.DINE_IN,
      icon:
        location?.pathname?.split("/")?.[1] === SideBarMenuData.DINE_IN ? (
          <DineIconSelected />
        ) : (
          <DineIconDefault />
        ),
    },
    {
      label: SideBarMenuTitle.ORDER,
      key: SideBarMenuData.ORDER,
      icon: <OrderIcon />,
    },
    {
      label: SideBarMenuTitle.SETTING,
      key: SideBarMenuData.SETTING,
      // icon: <SettingIcon />,
      icon:
        location?.pathname?.split("/")?.[1] === SideBarMenuData.SETTING ? (
          <SettingIcon />
        ) : (
          <SettingIconDark />
        ),
    },
  ];
  const onOpenChange = (keys) => {
    navigate(`/${keys?.key}`);
  };
  const ref = useRef();
  const sidebarRef = useRef();
  useOnClickOutside(ref, () => (logoutMenu ? setLogoutMenu(false) : null));
  useOnClickOutside(sidebarRef, () => (isActive ? setIsActive(false) : null));
  const handleClick = () => {
    setIsActive((preActive) => !preActive);
  };
  return (
    <LayoutAntd className="layout-contain">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={`${collapsed ? "close-collapsed" : ""} main-sider`}
      >
        <div>
          <div
            className="collapsed-icon"
            onClick={() => setCollapsed(!collapsed)}
          >
            <img
              src={collapseCloseIcon}
              className={`${collapsed ? "collapse-open" : ""}`}
              alt="collapse-icon"
            />
          </div>
          <div className="side-bar-image">
            <img
              onClick={() => {
                navigate("/dashboard");
              }}
              style={{ cursor: "pointer" }}
              alt="side-bar-image"
              width="102px"
              height="97px"
              src={sideBarImage}
            />
          </div>
        </div>
        <Menu
          mode="inline"
          onClick={onOpenChange}
          selectedKeys={[selectMenu]}
          items={sideBarMenu}
          className="menu-content"
        />
        <div className="logout-menu">
          <div className="logout-btn" onClick={logout}>
            <img height={"20px"} width={"19px"} src={logoutIcon} alt="Logout" />
            <div style={{ display: collapsed ? "none" : "block" }}>Logout</div>
          </div>
        </div>
      </Sider>
      {contextHolder}
      <LayoutAntd className="main-layout-contain">
        <Header className="main-header">
          <div className="menu" onClick={handleClick}>
            <div className="menu-line"></div>
            <div className="menu-line"></div>
            <div className="menu-line"></div>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
            ref={ref}
          >
            <div className="user-info">
              <div className="user-icon">
                {authUser?.displayName?.slice(0, 1).toUpperCase()}
              </div>
              <div className="username" onClick={() => setLogoutMenu(true)}>
                <span>{authUser?.displayName}</span>
                <MenuArrow />
              </div>
            </div>
            {logoutMenu && (
              <div className="header-logout-menu" onClick={logout}>
                <img
                  height={"20px"}
                  width={"19px"}
                  src={logoutIcon}
                  alt="Logout"
                />
                <div>Logout</div>
              </div>
            )}
            <div className="header-notification">
              <img alt="notification-icon" src={notificationIcon} />
            </div>
          </div>
        </Header>
        <div className={isActive ? "bg-overlay side-active" : "bg-overlay"}>
          <div className="responsive-sidebar" ref={sidebarRef}>
            <div className="side-bar-image">
              <img
                onClick={() => {
                  navigate("/dashboard");
                }}
                alt="side-bar-image"
                style={{ cursor: "pointer" }}
                src={sideBarImage}
              />
            </div>
            <Menu
              mode="inline"
              onClick={onOpenChange}
              selectedKeys={[selectMenu]}
              items={sideBarMenu}
              className="menu-content"
            />
            <div className="logout-menu" onClick={logout}>
              <div className="logout-btn">
                <img
                  height={"20px"}
                  width={"19px"}
                  src={logoutIcon}
                  alt="Logout"
                />
                <div>Logout</div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </LayoutAntd>
    </LayoutAntd>
  );
};
export default Layout;
