export class TableHeaderTitle {
  static SR_NO = "Sr. No";
  static CUSTOMER_NAME = "Customer Name";
  static RESTAURANT_NAME = "Restaurant Name";
  static TOTAL_GUESTS = "Total Guests";
  static DINE_IN_TIME = "Dine In Time";
  static CREATED_DATE = "Created Date";
  static ACTION = "Action";
  static ITEM_NAME = "Item Name";
  static OFFER = "Offer";
  static STATUS = "Status";
  static BUSINESS_NAME = "Business Name";
  static CONTACT_INFO = "Contact Info";
  static LOCATION = "Location";
  static REGISTERED_ON = "Registered on";
  static ORDER_ID = "Order ID";
  static CUSTOMER_INFO = "Customer Info";
  static TOTAL_AMOUNT = "Total Amount";
  static ORDER_STATUS = "Order Status";
  static ORDER_TYPE = "Order Type";
  static DELIVERY_DATE = "Delivery Date";
  static USER_NAME = "User Name";
  static USER_INFO = "User Info";
  static TOTAL_ORDERS = "Total Orders";
  static QUANTITY = "Qty";
  static PAYMENT_METHOD = "Payment Method";
  static PAYMENT_STATUS = "Payment Status";
  static PRICE = "Price";
  static DISCOUNT = "Discount";
  static TAX = "Tax";
  static TOTAL_PRICE = "Total Price";
  static OFFER_TITLE = "Offer Title";
  static OFFER_IMAGE = "Offer Image";
  static OFFER_DESCRIPTION = "Description";
  static OFFER_STATUS = "Status";
  static NUDGE_MESSAGE = "Message";
  static FOR_PUBLIC = "For Public";
  static APPLICATION_NAME = "Application Name";
  static MESSAGE_SETTING = "Message";
  static Ios = "Ios";
  static Android = "Android ";
  static MIN_FORCE_IOS = "Min Force version ios";
  static MIN_FORCE_ANDROD = "Min Force version Android";
}

export class TableData {
  static KEY = "key";
  static DISPLAY_NAME = "displayName";
  static CUSTOMER_INFO = "customerInfo";
  static TOTAL_ORDER = "totalOrders";
  static CREATED_AT = "createdAt";
  static OPERATION = "operation";
  static TOTAL_AMOUNT = "itemPrice";
  static BUSINESS_NAME = "businessName";
  static CONTACT_INFO = "contactInfo";
  static LOCATION = "location";
  static ORDER_ID = "orderId";
  static CREATED_BY = "createdBy";
  static TOTAL_MONEY = "totalMoney";
  static ORDER_STATUS = "orderStatus";
  static ORDER_TYPE = "orderType";
  static DELIVERY_DATE = "deliverDate";
  static USER_INFO = "userInfo";
  static ITEM_NAME = "itemName";
  static QUANTITY = "quantity";
  static PAYMENT_METHOD = "paymentMethod";
  static PAYMENT_STATUS = "paymentStatus";
  static BASE_PRICE_MONEY = "basePriceMoney";
  static TOTAL_DISCOUNT_MONEY = "totalDiscountMoney";
  static TOTAL_TAX_MONEY = "totalTaxMoney";
  static TOTAL_GUESTS = "guestCount";
  static RESTAURANT_NAME = "restaurantData";
  static CUSTOMER_NAME = "customerName";
  static NUDGE_OFFER_TITLE = "title";
  static NUDGE_OFFER_IMAGE = "photoURL";
  static NUDGE_MESSAGES = "message";
  static SLOT_TIME = "slotTime";
  static DINE_IN_DISCOUNT = "discount";
  static DINE_IN_BASE_PRICE = "basePrice";
  static DINE_IN_TOTAL_MONEY = "totalMoney";
  static DINE_IN_DETAILS_TAX = "tax";
  static NUDGE_STATUS = "status";
  static IS_PUBLIC_CHECK = "isPublic";
  static SERIAL_NUMBER = "serialNumber";
  static APP_TYPE = "appType";
  static SETTING_MESSAGE = "message";
  static IOS_CURRENT_BUILD_VERSION="currentBuildVersion"
  static IOS_MIN_BUILD_VERSION = "minVersion"
  static ANDROID_CURRENT_VERSION = "currentVersion"
  static ANDROID_MIN_BUILD_VERSION = "minVersionAndroid"

}
export class TableDataAlign {
  static ALIGN_CENTER = "center";
}

export class SideBarMenuTitle {
  static DASHBOARD = "Dashboard";
  static BUSINESS_USERS = "Business Users";
  static CUSTOMER = "Customer";
  static NUDGES = "Nudges";
  static DINE_IN = "Dine In";
  static ORDER = "Order";
  static SETTING = "Setting";
}

export class SideBarMenuData {
  static DASHBOARD = "dashboard";
  static BUSINESS_USERS = "business-users";
  static CUSTOMER = "customer";
  static NUDGES = "nudges";
  static DINE_IN = "dine-in";
  static ORDER = "order";
  static SETTING = "setting";
}
